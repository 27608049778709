
function About() {
    return (
        <>
        <div className="container-fluid topbar px-20 d-none d-lg-block">
          <div className="container-fluid px-20">
            <div className="row gx-0 align-items-center" style={{ height: 45 }}>
              <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                <div className="d-flex flex-wrap">
                  <a href="/" className="text-light me-4">
                    <i className="fas fa-phone-alt text-primary me-2" />
                    (+1) 832-225-8898
                  </a>
                  <a href="/" className="text-light me-0">
                    <i className="fas fa-envelope text-primary me-2" />
                    info@celersoft.com
                  </a>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <div className="d-flex align-items-center justify-content-end">
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-facebook-f text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-twitter text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-instagram text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-0"
                  >
                    <i className="fab fa-linkedin-in text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid sticky-top px-0">
          <div
            className="position-absolute bg-dark"
            style={{ left: 0, top: 0, width: "100%", height: "100%" }}
          ></div>
          <div className="container-fluid px-0">
            <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
              <a href="/" className="navbar-brand p-0">
                <img src="assets/img/logofooter.png" />

              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="fa fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                  <a href="/" className="nav-item nav-link active">
                    Home
                  </a>
                  <div className="nav-item dropdown">
                    <a
                      href="/"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      About Us
                    </a>
                    <div className="dropdown-menu m-0">
                    <a href="/management" className="dropdown-item">
                        Management
                      </a>
                      <a href="/" className="dropdown-item">
                        Managed Services
                      </a>
                    </div>
                  </div>
                  <a href="/" className="nav-item nav-link">
                    Services
                  </a>
                  <a href="/" className="nav-item nav-link">
                    Solutions
                  </a>
                  <a href="/" className="nav-item nav-link">
                    Careers
                  </a>
                  <a href="/" className="nav-item nav-link">
                    Contact
                  </a>
                </div>
                <div className="d-flex align-items-center flex-nowrap pt-xl-0">
                </div>
              </div>
            </nav>
          </div>
        </div>
  <div
    className="modal fade"
    id="searchModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content rounded-0">
        <div className="modal-header">
          <h4 className="modal-title mb-0" id="exampleModalLabel">
            Search by keyword
          </h4>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body d-flex align-items-center">
          <div className="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              className="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" className="input-group-text p-3">
              <i className="fa fa-search" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Search End */}
  {/* Header Start */}
  <div className="container-fluid bg-breadcrumb">
    <div className="bg-breadcrumb-single" />
    <div className="container text-center py-5" style={{ maxWidth: 900 }}>
      <h4
        className="text-white display-4 mb-4 wow fadeInDown"
        data-wow-delay="0.1s"
      >
        Management
      </h4>
     
    </div>
  </div>
  {/* Header End */}
 
  {/* Team Start */}
  <div className="container-fluid team py-5">
    <div className="container py-5">
      <div
        className="text-center mx-auto pb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 800 }}
      >
        <h4 className="text-primary">Not Colleagues</h4>
        <h1 className="display-4">WE ARE A TEAM</h1>
      </div>
      <div className="row g-4 justify-content-center">
        <div
          className="col-sm-6 col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="team-item rounded">
            <div className="team-img">
              <img
                src="assets/img/team-1.jpg"
                className="img-fluid w-100 rounded-top"
                alt="Image"
              />
              <div className="team-icon">
                <a
                  className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                  href=""
                >
                  <i className="fas fa-share-alt" />
                </a>
                <div className="team-icon-share">
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-0"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
            <div className="team-content bg-dark text-center rounded-bottom p-4">
              <div className="team-content-inner rounded-bottom">
                <h4 className="text-white">Mark D. Brock</h4>
                <p className="text-muted mb-0">CEO &amp; Founder</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-6 col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="team-item rounded">
            <div className="team-img">
              <img
                src="assets/img/team-2.jpg"
                className="img-fluid w-100 rounded-top"
                alt="Image"
              />
              <div className="team-icon">
                <a
                  className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                  href=""
                >
                  <i className="fas fa-share-alt" />
                </a>
                <div className="team-icon-share">
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-0"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
            <div className="team-content bg-dark text-center rounded-bottom p-4">
              <div className="team-content-inner rounded-bottom">
                <h4 className="text-white">Mark D. Brock</h4>
                <p className="text-muted mb-0">CEO &amp; Founder</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-6 col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="team-item rounded">
            <div className="team-img">
              <img
                src="assets/img/team-3.jpg"
                className="img-fluid w-100 rounded-top"
                alt="Image"
              />
              <div className="team-icon">
                <a
                  className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                  href=""
                >
                  <i className="fas fa-share-alt" />
                </a>
                <div className="team-icon-share">
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-0"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
            <div className="team-content bg-dark text-center rounded-bottom p-4">
              <div className="team-content-inner rounded-bottom">
                <h4 className="text-white">Mark D. Brock</h4>
                <p className="text-muted mb-0">CEO &amp; Founder</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-6 col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.7s"
        >
          <div className="team-item rounded">
            <div className="team-img">
              <img
                src="assets/img/team-4.jpg"
                className="img-fluid w-100 rounded-top"
                alt="Image"
              />
              <div className="team-icon">
                <a
                  className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                  href=""
                >
                  <i className="fas fa-share-alt" />
                </a>
                <div className="team-icon-share">
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-3"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-primary btn-sm-square text-white rounded-circle mb-0"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
            <div className="team-content bg-dark text-center rounded-bottom p-4">
              <div className="team-content-inner rounded-bottom">
                <h4 className="text-white">Mark D. Brock</h4>
                <p className="text-muted mb-0">CEO &amp; Founder</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Team End */}
  <div className="container-fluid footer py-3 wow fadeIn" data-wow-delay="0.2s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <div className="footer-item d-flex flex-column">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Home
                    </a>
                    <a href="/management">
                      <i className="fas fa-angle-right me-2" /> About Us
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Services
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Solutions
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Careers
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">Contact Info</h4>
                <div className="single_address">
                  <p>
                    <span style={{ fontSize: 20 }}>
                      <em>
                        <strong>USA</strong>
                      </em>
                    </span>
                  </p>
                  <p>
                    1500 S Dairy Ashford Rd
                    <br />
                    Ste 355 Houston
                    <br />
                    TX 77077
                  </p>
                  <hr />
                  <p>
                    13501 Katy FWY,
                    <br />
                    Suite: 1540, Houston,
                    <br />
                    Texas- 77079
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">&nbsp;</h4>
                <div className="single_address">
                  <p>
                    <em>
                      <span style={{ fontSize: 20 }}>
                        <strong>CANADA</strong>
                      </span>
                    </em>
                  </p>
                  <p>
                    998 Loft Court,
                    <br />
                    London, Ontario
                    <br />
                    N6G0J9
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <h4 className="text-white mb-4">&nbsp;</h4>
                  <a href="">
                    <i className="fas fa-envelope me-2" /> info@celersoft.com
                  </a>
                  <a href="">
                    <i className="fas fa-phone me-2" /> (+1) 832-225-8898
                  </a>
                  <hr />
                  <div className="d-flex align-items-center">
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-instagram" />
                    </a>
                    <a className="btn btn-light btn-md-square me-0" href="">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid copyright py-3">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 text-center text-md-start mb-md-0">
                <span className="text-body">
                  <i className="fas fa-copyright text-light me-1" />
                  2024 Celersoft. All rights reserved.
                </span>
              </div>
              <div className="col-md-6 text-center text-md-end text-body">
              <a
                    className="border-bottom text-primary"
                    href="https://htmlcodex.com"
                >
                    HTML Codex
                </a>{" "}
                Distributed By{" "}
                <a
                    className="border-bottom text-primary"
                    href="https://themewagon.com"
                >
                    ThemeWagon
                </a>


              </div>
            </div>
          </div>
        </div>

        <a href="/" className="btn btn-primary btn-lg-square back-to-top">
          <i className="fa fa-arrow-up" />
        </a>
</>



      
      
    );
};

export default About