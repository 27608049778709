import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';

function App() {
    return (
      <div className="App">
        <Router>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/management" element={<About />} />
         </Routes>
        </Router>
      </div>
    );
}

export default App;

